<template>
  <div class="router-box">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "App",
  computed: {
    ...mapGetters("user", ["isLogin"]),
  },
  mounted() {
    console.log("this", this);
    // 储存用户数据
    this.setUserInfo();
    // 获取权限信息
    this.setAuth();
  },
  
  methods: {
    ...mapMutations("app", ["setMenuList", "setNodeList"]),
    ...mapMutations("user", ["setAuth", "setUserInfo"]),
    ...mapActions("user", ["getAuth"]),
  },
};
</script>

<style lang="scss">
@import "//at.alicdn.com/t/c/font_3254884_3u6drm3o378.css";
@import "@/assets/style/theme.scss";
@import "@/assets/style/default.scss";
</style>
